body {
  background-color: #fafafa;
  margin: 0 0 0 0;
}

.App {
  text-align: center;
}

.logo {
  position: relative;
}

.logo img {
  max-height: 66px;
  position: absolute;
  top: -12px;
  left: 10px;
}

.header-navigation {
  margin-right: auto;
  margin-left: 20px;
  margin-bottom: 10px;
}
.header-navigation > a {
  margin-left: 30px;
  text-decoration: none;
}
.header-navigation > a > button {
  padding: 5px 16px;
}

.navlink-active > button {
  border: 1px solid rgba(63, 81, 181, 0.5);
}

.logo span {
  margin: 5px 0 0 70px;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-size: 36px;
  background: rgb(59, 97, 255);
  background: linear-gradient(
    90deg,
    rgba(59, 97, 255, 1) 0%,
    rgba(73, 199, 244, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.heading-separator {
  font-size: 1.1rem !important;
  padding: 10px 0px;
  text-align: center;
  text-transform: uppercase;
  color: #bfbfbf;
}

.app-state-row {
  padding: 3px 3px 3px 3px;
  border-radius: 3px;
  height: 26px;
}
.app-state-row:hover {
  background-color: #fafafa;
}

.MuiFormControl-root.user-select-form-control {
  margin: 10px 0px;
}

.user-select-item {
  background-color: green;
}

.user-select-item > .MuiAvatar-root,
.user-select-root > .MuiSelect-root > .MuiAvatar-root {
  width: 20px;
  height: 20px;
  border: 1px solid #b7b7b7;
  margin: 0px 10px 0 0;
}
.user-select-item > span {
  font-size: 14px;
}

.user-select-root > .MuiSelect-root {
  display: flex;
  align-items: center;
  width: 116px;
  height: 22px;
}

.user-select-root > .MuiSelect-root > span {
  font-size: 14px;
  max-width: 90px;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Overrides MUI - Material UI */
.mui--icon-text-button {
  padding: 3px 10px 3px 4px !important;
  border-radius: 20px !important;
}
/* End – Overrides MUI - Material UI */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiTableCell-root {
  padding: 15px 10px 15px 20px !important;
}

.menu-item-link {
  background-color: pink;
  display: block;
  width: 100%;
  height: 100%;
}
